import React, { useEffect, useState } from "react";
// import Slider from 'react-slick';
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../Utils/Authenticated";
import CustomInput from "../../components/Inputs/borderedInput";
import InputField from "../../components/Inputs/InputField";
import { Button, Divider, ListItem } from "@material-ui/core";
import { request } from "../../Utils/Request";
import moment from "moment";
import { Toast } from "../../Utils/Notifications";
import FFModal from "../../components/Modal";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { insertMessageListen, InsertChatMessage } from "../../Utils/Socket";
const Message = (props) => {
  const [chatHistory, setChatHistory] = useState({
    chatHis: [],
  });
  const [buyerIds, setBuyerIds] = useState([]);
  const [dis, setDis] = useState(false);
  const [visitorModalOpen, setVisitorModalOpen] = useState(false);
  const [values, setValues] = useState({
    inputMessage: "",
  });
  const [user] = useState({
    userId: localStorage.getItem("userId"),
    fname: localStorage.getItem("userFirstName"),
    lname: localStorage.getItem("userLastName"),
    email: localStorage.getItem("userEmail"),
    username: localStorage.getItem("user-name"),
  });

  const handlechange = async (e) => {
    setValues({ ...values, inputMessage: e.target.value });
  };

  useEffect(() => {
    // if (!isAuthenticated()) {
    //   props.history.push("/login");
    // }
    // console.log("useeffect", props.seller_id)
    // auctionedProduct(props.match.params.id);
    if (props.project_id) {
      getChatHistory(props.project_id);
    }
  }, [props.project_id]);

  // useEffect(()=>{
  //     if(props.to_id === localStorage.userid)
  //     {
  //       console.log("hiiiiiiiiiiiiiiiiiiii")

  //     }

  // },[dis])

  const getChatHistory = async (product_id) => {
    try {
      const res = await request(
        "post",
        "/get_chathistory2",
        { project_id: product_id },
        false,
        true
      );

      console.log("chathis", res.data.data);
      if (res.data.status === "yes") {
        var msgdis = [];
        res.data.data.map((val) => {
          if (val.message.includes("-")) {
            msgdis = val.message.toString().split("-");

            val.message = msgdis[0];
            if (val.from_id === props.seller_id) {
              val.name = msgdis[1];
            } else {
              val.name =
                msgdis[1][0] +
                "***" +
                msgdis[1][msgdis[1].toString().length - 1];
            }
          }
        });

        setChatHistory({
          ...chatHistory,
          chatHis: res.data.data,
        });
        const filtered = res.data.data.filter(
          (item) => item.from_id !== props.seller_id
        );
        const fromIds = filtered.map((item) => item.from_id);

        setBuyerIds([...new Set(fromIds)]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const keyp = (e) => {
    if (e.key === "Enter" && e.target.value) {
      console.log("preesss");
      sendMessage();
    }
  };

  const sendMessage = async (e) => {
    console.log("send");

    //Check if user is a buyer or not
    if (!isAuthenticated() || localStorage.getItem("userRole") === "tier1") {
      setVisitorModalOpen(true);
    } else {
      if (
        values.inputMessage != "" &&
        values.inputMessage != null &&
        props.status === "open"
      ) {
        try {
          const reqData =
            props.seller_id === props.from_id
              ? {
                  subject: `Message From Seller!`,
                  send_mail: 1,
                  fname: user.fname,
                  lname: user.label,
                  toemail: user.email,
                  from_id: props.seller_id,
                  to_id: user.userId,
                  project_id: props.project_id,
                  message: `${values.inputMessage}-${user.username}`,
                  sellertobuyer: 1,
                  userId: buyerIds,
                  project_title: props.proddetails?.title
                }
              : {
                  subject: `Query Mail From Buyer!`,
                  send_mail: 1,
                  fname: props.sellFname,
                  lname: props.sellLname,
                  toemail: props.sellEmail,
                  from_id: props.from_id,
                  to_id: props.to_id,
                  project_id: props.project_id,
                  message: `${values.inputMessage}-${props.buyName}`,
                  sellertobuyer: 0,
                  project_title: props.proddetails?.title
                };
          const res = await request(
            "post",
            "/insertMessages2",
            reqData,
            false,
            true
          );

          console.log(res.data);
          if (res.data.status === "yes") {
            // InsertChatMessage(`${values.inputMessage}-${props.buyName}`)
            getChatHistory(props.project_id);
          }
          setValues({ ...values, inputMessage: "" });
          // setDis(true)
        } catch (error) {
          console.log(error);
        }
      } else {
        if (props.status !== "open") {
          Toast.fire({
            type: "warning",
            title:
              "We love your excitement but please hold off, the chat will become active when the Sale goes Live",
          });
        }
      }
    }
  };

  const vistorModalClosehandler = () => {
    setVisitorModalOpen(false);
  };

  return (
    <div>
      <div className="query-wrapper">
        {chatHistory.chatHis.map((data, index) => (
          <div>
            {data.from_id === props.seller_id ? (
              <div className="msg-wrap">
                <div className="msg-con">{data.message}</div>
                <div className="msg-date">
                  {moment(data.subject).format("DD/MM/YYYY HH:mm:ss")}
                  <div className="msg-user">
                    <span className="material-icons">account_circle</span>{" "}
                    <span>{data.name}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="msg-wrap cu-msg">
                <div className="msgUserInfo">
                  <div className="msg-date">
                    {moment(data.subject).format("DD/MM/YYYY HH:mm:ss")}
                  </div>
                  <div className="msg-user">
                    <span className="material-icons">account_circle</span>{" "}
                    <span>{data.name}</span>
                  </div>
                </div>
                <div className="msg-con">{data.message}</div>
              </div>
            )}
          </div>
        ))}
        {/* <div className="msg-wrap cu-msg">
          <div className="msg-date">19/10/2020 | 18:25PM</div>
          <div className="msg-con">Hi There</div>

          <div className="msg-user">
           {" "}
            <span className="material-icons">account_circle</span>
          </div>
        </div> */}
        {/* <div className="msg-wrap cu-msg">
          <div className="msg-date">19/10/2020 | 18:30PM</div>
          <div className="msg-con">I m a buyer</div>
          <div className="msg-user">
           {" "}
            <span className="material-icons">account_circle</span>
          </div>
        </div> */}
      </div>
      <div className="query-input-wrap">
        <CustomInput
          className="form-control"
          placeholder="Type your message here..."
          value={values.inputMessage}
          onchange={handlechange}
          disableTooltip={true}
          onKeyPress={keyp}
        />
        <Button className="blue-btn" onClick={sendMessage}>
          SEND
        </Button>
      </div>
      <FFModal
        open={visitorModalOpen}
        handleClose={vistorModalClosehandler}
        modaltitle="Message"
      >
        <div style={{ fontWeight: "500" }}>
          <p className="card-text">
            If you wish to participate in the Sales Rooms please register as
            Buyer
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={vistorModalClosehandler}>Cancel</Button>

            <PrimaryButton
              label={"Register"}
              onsubmit={() => {
                if (!isAuthenticated()) {
                  window.location.href = "/Reg_tier_1";
                } else {
                  window.location.href = "/Reg_tier_2";
                }
              }}
            />
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default Message;
