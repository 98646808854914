import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import "./Registration.css";
import { isAuthenticated } from "../../Utils/Authenticated";
import { ValidateRegisterationtier_2 } from "../../Utils/Validators";
import { IrishCounties, Countries, HerdNum } from "../../Utils/constant";
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request";
import { Popup, Toast } from "../../Utils/Notifications";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import InputField from "../../components/Inputs/InputField";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import SelectField from "../../components/Inputs/SelectField";
import CheckBox from "../../components/Inputs/CheckBox";
import { Link } from "react-router-dom";
import JwtDecode from "jwt-decode";
import { TramRounded } from "@material-ui/icons";
import { FormHelperText, Popover, Button, ListItem } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from "moment";
var allHerdNumbers = "";

const Reg_tier_2 = (props, history) => {
  console.log(localStorage.userRole);
  const [btnloading,setBtnloading]=useState(false)
  useEffect(() => {
    if (isAuthenticated() /*  && localStorage.userRole === "tier1"  */) {
      props.history.push("/Reg_tier_2");
    } else {
      props.history.push("/");
    }
    //setValues({...values,herd_number:["","","","",""]})

    setValues({
      ...values, 
        present_buildingName: "",
        present_street_name: "",
        present_town: "",
        present_county: "",
      })
    UserProfile()
  }, []);

  const UserProfile=async()=>{
    try { 
      var arr=["","","","",""];
      const res = await request(
        "post",
        "/getUserDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      
      const userData = JwtDecode(res.data.data);
      
      if (res.data.status === "yes") {
        console.log('getUserProfileDetails', userData.registered_number);
        allHerdNumbers="";
        userData.registered_number.split(" ").map((val,key)=>{
          if(val!=="") {
            arr.splice(key,1,val)
            allHerdNumbers+=val+' ';
            
            if(key>4){
              add.a.push(key)
            }
          }
        })
        
        setValues({
        ...values, 
          present_buildingName: userData.address1,
          present_street_name: userData.address2,
          phone_number: userData.phone?userData.phone:"+353",
          present_town: userData.city,
          present_country: userData.country?userData.country:'Ireland',
          present_county: userData.state,
          present_eir_code: userData.zip,
          herd_number: arr,
          registered_number:arr[0]?arr[0]:''
        })

      } else {
        console.log("error");
      }
    } catch (error) {
		console.log(error);
	  }
	}

  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state,
    setValues,
    setState,
  } = UseForm(formSubmit, ValidateRegisterationtier_2);
  const [loader, setLoader] = useState(false);

  const [anchorE2, setAnchorE2] = useState(null);
  const [open, setOpen] = useState(false);
  const openPop1 = Boolean(anchorE2);
  const oneClick = openPop1 ? "one-click-popover" : undefined;
  const [add, setAdd] = useState({
    a: [0, 1, 2, 3, 4],
  });
  //Handle Dialog box

  const handleClickOpen = () => {
    setOpen(true);

  };
  const popClose1 = () => {
    setAnchorE2(null);
  };
  const handlePhoneChange=(e)=>{
    console.log("+++++++++++++",e)
    setValues({...values,phone_number:e})
  }

  //Handle Dialog box
  const handleClose = () => {
    allHerdNumbers="";
    
    add.a.map((key) => {
      if (values.herd_number[key] != undefined) {
        if(values.herd_number!="")
        {
          allHerdNumbers = allHerdNumbers + values.herd_number[key] + " ";
        }
      }
      // setValues({...values,herd:allHerdNumbers?allHerdNumbers:"@"})
      // if(allHerdNumbers.length===1){
      //   setValues({...values,herd_number:["","","","",""]})
      // }
    
    });

    setOpen(false);
  };
  const handleClose1 = () => {
    allHerdNumbers="";
    setOpen(false);
    setValues({...values,herd_number:["","","","",""]})
    setAdd({...add,a:[0,1,2,3,4]})
  };


  const newrow = (v) => (e) => {
    add.a.push(v);
    setAdd({ ...add });
    values.herd_number.splice(v,1,"");
    setValues({...values})
  };
  const onHerdchange = (e) => {
    const { id, name, value } = e.target;

    console.log("+++++++k", id, name, value);
    //  if(values.[name][key])
    // if (values[name][id] === undefined) {
    //   setValues((prevState) => ({
    //     ...prevState,
    //     [name]: [...prevState[name], value],
    //   }));
    // } else {
    //   values[name].splice(id, 1, value);
    //   setValues({ ...values });
    // }
    values[name].splice(id,1,value);
    setValues({...values})
  };

  //Concat herd Values
  // var herd1 = values.herd_number_1? `${values.herd_number_1}`:""
  // var herd2 = values.herd_number_2? ` ,${values.herd_number_2}`:""
  // var herd3 = values.herd_number_3? ` ,${values.herd_number_3}`:""
  // var herd4 = values.herd_number_4? ` ,${values.herd_number_4}`:""
  // var herd5 = values.herd_number_5? ` ,${values.herd_number_5}`:""

  var address1 = `${values.present_buildingName}  ${values.present_street_name} ${values.present_town} ${values.present_county}  ${values.present_eir_code} ${values.present_country} `;
  var address2 = `${values.collection_buildingName}  ${values.collection_street_name} ${values.collection_town} ${values.collection_county}  ${values.collection_eir_code} ${values.collection_country}`;
  var address3 = `${values.delivery_buildingName}  ${values.delivery_street_name} ${values.delivery_town} ${values.delivery_county}  ${values.delivery_eir_code} ${values.delivery_country}`;

  /* console.log(address1)
console.log(address2)
console.log(address3)
 */
  async function formSubmit() {
    setBtnloading(true)
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      console.log(res.data);
      console.log("Testing res data");
      if ((res.data.success = "yes")) {
        const form_data = {
          user_id: res.data.data.id,
          email: res.data.data.email,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          username: res.data.data.username,
          registered_number: values.herd_temp, //registered number added as herd_number
          phone: values.phone_number,
          address1: values.present_buildingName, //building name stored as address 1
          address2: values.present_street_name, //street name stored as address2
          state: values.present_county, //county stored as state
          city: values.present_town, // town stored as city
          zip: values.present_eir_code, //zipcode added as eircode
          country: values.present_country,
          profile_image:res.data.data.avatar,
          user_profile_image: res.data.data.avatar,
          company_name:res.data.data.company_name,
          role: "tier2",
          //collection address stored as mailing address
          mailing_address: values.checkedA ? address1 : address2,
          //delivery address stored as secondary mailing address
          secondary_mailing_address: values.checkedB ? address1 : address3,
        };
        var address = {
          user_id: JwtDecode(isAuthenticated()).id,
          name: values.present_buildingName,
          address: values.present_street_name,
          country: values.present_country,
          state: values.present_county,
          city: values.present_town,
          zipcode: values.present_eir_code,
        };
         request("post", "/addnewaddress", address, false, true);

        if (!values.checkedA) {
          var addresss = {
            user_id: JwtDecode(isAuthenticated()).id,
            name: values.collection_buildingName,
            address: values.collection_street_name,
            country: values.collection_country,
            state: values.collection_county,
            city: values.collection_town,
            zipcode: values.collection_eir_code,
          };
          await request("post", "/addnewaddress", addresss, false, true);
        }
        if (!values.checkedB) {
          var addre = {
            user_id: JwtDecode(isAuthenticated()).id,
            name: values.delivery_buildingName,
            address: values.delivery_street_name,
            country: values.delivery_country,
            state: values.delivery_county,
            city: values.delivery_town,
            zipcode: values.delivery_eir_code,
          };
          await request("post", "/addnewaddress", addre, false, true);
        }

        const response = await request(
          "post",
          "/updateUserProfile",
          form_data,
          false,
          true
        );
        console.log(form_data);
        console.log(response.data);
        setBtnloading(false)
        if (response.data.status === "yes") {
          localStorage.setItem("userRole", form_data.role);
          setLoader(false);
          setState(false);

          // add to mailchimp list
          if (!window.location.hostname.includes("localhost")){
          if (localStorage.mailchimpID && localStorage.mailchimpID != ""){
            const res = await request("post", "/mailchimp", {
              type: "mailchimp",
              userid: localStorage.userId,
              email: localStorage.userEmail,
              role: "tier2",
              mailchimp_id: localStorage.mailchimpID,
            });
          } else {
            const res = await request("post", "/mailchimp", {
              type: "mailchimp",
              userid: localStorage.userId,
              email: localStorage.userEmail,
              role: "tier2",
            });
          }
          }

          localStorage.clear();
          localStorage.setItem("newspopup", 1)
          await props.history.push("/login");
          return Popup.fire({
            type: "success",
            title: "Congratulations, you've registered as a Buyer. Happy Bidding.",
          });
        }
      }
      setLoader(false);
      setBtnloading(false)
    } catch (error) {
      setLoader(false);
      console.log("++++", error);
      return Popup.fire({
        type: "error",
        title: "Something went wrong, please try again",
      });
    }
  }

  const popClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  console.log(values);
  return (
 
    <div className="ffReg reg2">
         {console.log("+++++++++++++++++++++++",errors)}
      <div className="ffRegCnt">
        <Link to="/">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="75" />
        </Link>
        <div className="greetMsg">
          <Popover
            id={oneClick}
            open={openPop1}
            anchorEl={anchorE2}
            onClose={popClose1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <p className="pover-wrap">
              If you wish to register a seller please register first as a buyer
              and we will bring you to seller registration. Don't worry, we've
              set it up this way so it's the quickest it can be.
            </p>
          </Popover>
          <h2 className="playFair">
            Buyer Registration{" "}
            <Button onClick={popClick1}>
              <span className="material-icons">info</span>
            </Button>{" "}
          </h2>
          <p className="playFair">
            Register as a Buyer to bid on the best choice of livestock.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
            <div className="ffRegInp phony">
              {/* <InputField
                label="Phone Number*"
                placeholder="Enter your phone number"
                type="text"
                con={"phone"}
                name="phone_number"
                value={values.phone_number}
                onchange={handleChange}
              />
              <p style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.phone_number : null}
              </p> */}
              <PhoneInput 
					   placeholder="Phone Number"
					   value={values.phone_number}
					    country={"ie"}
              autoFormat={false}
					   onChange={handlePhoneChange}/>
					   <FormHelperText error>{state && errors.phone_number}</FormHelperText>
            </div>
            <div className="ffRegInp herding">
              <InputField
                label="Herd Number*"
                placeholder="Enter your herd number"
                type="text"
                //disabled={true}
                // con={true}
                con={"hd"}
                name="herd_temp"
                value={values.herd_temp}
                onchange={handleChange}
              />

              <p style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.herd_temp : null}
              </p>
              {/* <div className="d-flex justify-content-between">
                <div>
                  <a className="addMoreHerd" onClick={handleClickOpen}>
                    <span className="material-icons">add_circle</span>
                    ADD HERD NUMBER
                  </a>
                </div>
                <Tooltip
                  title="We need this for when you buy livestock, so the correct details can be input"
                  placement="top-bottom"
                >
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons">help</span> 
                    
                  </ListItem>
                </Tooltip>
              </div> */}
            </div>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                {add.a.map((key) => (
                  <InputField
                    label="Herd Number"
                    placeholder="Enter your herd number"
                    type="text"
                    con={values.herd_number[key]!=undefined && Object.keys(values.herd_number[key]).length==10?"dallow":"hd"}
                    id={key}
                    name="herd_number"
                    value={values.herd_number[key]}
                    onchange={onHerdchange}
                  />
                ))}
              </DialogContent>

              <DialogActions>
                <CancelIcon
                  onClick={handleClose1}
                  style={{ color: "#f44336" }}
                />
                <DoneIcon
                  onClick={handleClose}
                  style={{ color: "var(--primColor)" }}
                />
                <AddIcon
                  onClick={newrow(add.a.length + 1)}
                  style={{ color: "var(--primColor)" }}
                />
              </DialogActions>
            </Dialog>
          </div>

          {/*///////////// Present Address ////////////////*/}
          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex ffRegTitle no-wrap" style={{ flexWrap : "nowrap"}}>
              <h3>Present address</h3>
              <div>
                <Tooltip
                  title="Please be as accurate as possible, as this information is used to work out the costs for haulage of
livestock."
                  placement="top-bottom"
                >
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons" style={{ marginRight : "0px" }}>help</span> 
                    {/* Why is this
                    required */}
                  </ListItem>
                </Tooltip>
              </div>
            </div>

            <div className="ffRegInp">
              <InputField
                label="Address Line 1"
                placeholder="Enter your Address Line 1"
                type="text"
                // con={false}
                name="present_buildingName"
                value={values.present_buildingName}
                onchange={handleChange}
              />
              <p className="error" style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.present_buildingName : null}
              </p>
            </div>

            <div className="ffRegInp">
              <InputField
                label="Townsland / Address Line 2*"
                placeholder="Enter your Townsland / Address Line 2"
                type="text"
                con={"sn"}
                name="present_street_name"
                value={values.present_street_name}
                onchange={handleChange}
              />
              <p className="error" style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.present_street_name : null}
              </p>
              <div>
                <Tooltip
                  title="This is your “Townsland” - helps with accuracy for working out Haulage Charges."
                  placement="top-bottom"
                >
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons">help</span> 
                    {/* Why is this
                    required */}
                  </ListItem>
                </Tooltip>
              </div>
            </div>

            <div className="ffRegInp">
              <InputField
                label="Town*"
                placeholder="Enter your town"
                type="text"
                name="present_town"
                con={false}
                value={values.present_town}
                onchange={handleChange}
              />
              <p className="error" style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.present_town : null}
              </p>
              <div>
                <Tooltip
                  title="This is your closet “Town” - helps with accuracy for working out Haulage Charges."
                  placement="top-bottom"
                >
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons">help</span> 
                    {/* Why is this
                    required */}
                  </ListItem>
                </Tooltip>
              </div>
            </div>

            <div className="ffRegInp">
              <SelectField
                label="County*"
                name="present_county"
                selectMenu={IrishCounties}
                value={values.present_county}
                onchange={handleChange}
              />
              <p className="error" style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.present_county : null}
              </p>
            </div>

            <div className="ffRegInp eirCode">
              <InputField
                label="EIR Code*"
                placeholder="Enter your EIR code."
                type="text"
                name="present_eir_code"
                value={values.present_eir_code}
                onchange={handleChange}
                con={values.present_eir_code.length==8?"dallow":"sn"}
              />
              <p className="eirCode_small" style={{ color: "red" }}>
                {state ? errors.present_eir_code : null}
              </p>
              <div>
                <Tooltip
                  title=" This helps with accuracy for working out Haulage Charges."
                  placement="top-bottom"
                >
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons">help</span> 
                    {/* Why is this
                    required */}
                  </ListItem>
                </Tooltip>
              </div>
            </div>
            <div className="ffRegInp">
              <SelectField
                label="Country*"
                name="present_country"
                selectMenu={Countries}
                value={values.present_country}
                onchange={handleChange}
                con={ false}
              />
              <p style={{ color: "red", fontSize: "12px" }}>
                {state ? errors.present_country : null}
              </p>
            </div>
          </div>

          {/*///////////// Collection Address ////////////////*/}

          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
            <div className="ffRegTitle d-flex justify-content-between align-items-center">
              <h3>Collection address</h3>

              <CheckBox
                label="Same as present address"
                onchange={handleChange}
                name={"checkedA"}
                value={values.checkedA}
                click={() => !values.checkedA}
              />
            </div>
            {!values.checkedA && (
              <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedA ? "Address Line 1" : null}
                    placeholder="Enter your Address Line 1"
                    type="text"
                    con={false}
                    name="collection_buildingName"
                    value={
                      values.checkedA
                        ? values.present_buildingName
                        : values.collection_buildingName
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p
                    style={{ color: "red", fontSize: "12px", fontSize: "12px" }}
                  >
                    {state ? errors.collection_buildingName : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedA ? "Townsland / Address Line 2*" : null}
                    placeholder="Enter your Townsland / Address Line 2 name"
                    type="text"
                    con={"sn"}
                    name="collection_street_name"
                    value={
                      values.checkedA
                        ? values.present_street_name
                        : values.collection_street_name
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p
                    style={{ color: "red", fontSize: "12px", fontSize: "12px" }}
                  >
                    {state ? errors.collection_street_name : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedA ? "Town*" : null}
                    placeholder="Enter your town"
                    con={false}
                    type="text"
                    name="collection_town"
                    value={
                      values.checkedA
                        ? values.present_town
                        : values.collection_town
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p
                    style={{ color: "red", fontSize: "12px", fontSize: "12px" }}
                  >
                    {state ? errors.collection_town : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <SelectField
                    label={
                      !values.checkedA ? "County*" : `${values.present_county}`
                    }
                    name="collection_county"
                    selectMenuHeader={"select county*"}
                    selectMenu={IrishCounties}
                    value={
                      values.checkedA
                        ? values.present_county
                        : values.collection_county
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p
                    style={{ color: "red", fontSize: "12px", fontSize: "12px" }}
                  >
                    {state ? errors.collection_county : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp eirCode">
                  <InputField
                    label={!values.checkedA ? "EIR Code*" : null}
                    placeholder="Enter your EIR code"
                    type="text"
                    con={values.collection_eir_code.length==8?"dallow":"sn"}
                    name="collection_eir_code"
                    value={
                      values.checkedA
                        ? values.present_eir_code
                        : values.collection_eir_code
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.collection_eir_code : null}{" "}
                  </p>
                </div>
                <div className="ffRegInp">
                  <SelectField
                    label={
                      !values.checkedA
                        ? "Country*"
                        : `${values.present_country}`
                    }
                    name="collection_country"
                    selectMenuHeader={"select country*"}
                    selectMenu={Countries}
                    value={
                      values.checkedA
                        ? values.present_country
                        : values.collection_country
                    }
                    disabled={values.checkedA ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.collection_country : null}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/*///////////// Delivery Address ////////////////*/}

          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
            <div className="ffRegTitle d-flex justify-content-between align-items-center">
              <h3>Delivery address</h3>
              <CheckBox
                label="Same as present address"
                onchange={handleChange}
                name={"checkedB"}
                value={values.checkedB}
                click={() => !values.checkedB}
              />
            </div>
            {!values.checkedB && (
              <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedB ? "Address Line 1" : null}
                    placeholder="Enter your Address Line 1"
                    type="text"
                    con={false}
                    name="delivery_buildingName"
                    value={
                      values.checkedB
                        ? values.present_buildingName
                        : values.delivery_buildingName
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_buildingName : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedB ? "Townsland / Address Line 2*" : null}
                    placeholder="Enter your Townsland / Address Line 2* name"
                    type="text"
                    con={"sn"}
                    name="delivery_street_name"
                    value={
                      values.checkedB
                        ? values.present_street_name
                        : values.delivery_street_name
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_street_name : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <InputField
                    label={!values.checkedB ? "Town*" : null}
                    placeholder="Enter your town"
                    type="text"
                    con={false}
                    name="delivery_town"
                    value={
                      values.checkedB
                        ? values.present_town
                        : values.delivery_town
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_town : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp">
                  <SelectField
                    label={
                      !values.checkedB ? "County*" : `${values.present_county}`
                    }
                    name="delivery_county"
                    selectMenuHeader={"select county"}
                    selectMenu={IrishCounties}
                    value={
                      values.checkedB
                        ? values.present_county
                        : values.delivery_county
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_county : null}{" "}
                  </p>
                </div>

                <div className="ffRegInp eirCode">
                  <InputField
                    label={!values.checkedB ? "EIR Code*" : null}
                    placeholder="Enter your EIR code"
                    type="text"
                    con={values.delivery_eir_code.length==8?"dallow":"sn"}
                    name="delivery_eir_code"
                    value={
                      values.checkedB
                        ? values.present_eir_code
                        : values.delivery_eir_code
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_eir_code : null}{" "}
                  </p>
                </div>
                <div className="ffRegInp">
                  <SelectField
                    label={
                      !values.checkedB
                        ? "Country*"
                        : `${values.present_country}`
                    }
                    name="delivery_country"
                    selectMenu={Countries}
                    selectMenuHeader={"select country*"}
                    value={
                      values.checkedB
                        ? values.present_country
                        : values.delivery_country
                    }
                    disabled={values.checkedB ? true : false}
                    onchange={handleChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {state ? errors.delivery_country : null}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/*///////////// End of Addresses ////////////////*/}

          <div className="agreeTerms">
            <CheckBox
              label={
                <p>
                  <span>
                    I confirm that I have read, understand, and agree to the{" "}
                  </span>
                  <a
                    style={{ color: "green" }}
                    target={"_blank"}
                    href={"/terms"}
                  >
                    Terms & Conditions
                  </a>
                  <span> and </span>
                  <a
                    style={{ color: "green" }}
                    target={"_blank"}
                    href={"/privacypolicy"}
                  >
                    Privacy Policy
                  </a>
                  <span> of Farm Fayre.</span>
                </p>
              }
              name="terms"
              /* value={values.singleitemlot_auction} */
              value={values.terms}
              click={() => !values.terms}
              onchange={handleChange}
            />
            <FormHelperText error>{state && errors.terms}</FormHelperText>
          </div>

          <div className="actBtn">
            <PrimaryButton label="SUBMIT" dis={btnloading} type="submit" />
          </div>
        </form>

        {/*        <div className="accHelp">
          <p>Already have an account?</p>
          <span>Login</span> <a href="#">here</a>
        </div> */}
      </div>
      <p className="footerText">© {moment().year()} Farm Fayre. All rights reserved.</p>
    </div>
  );
};

export default Reg_tier_2;
