import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import DBLayout from "../../components/DBLayout";
import ReactHtmlParser from "react-html-parser";
import { request } from "../../Utils/Request";
import { Toast, Popup } from "../../Utils/Notifications";
import { callNumber, callNumber1 } from "../../Utils/constant";
import Closed from "../../components/Auctions/Closed";
import Active from "../../components/Auctions/Active";
import Sold from "../../components/Auctions/Sold";
import "./Feeds.css";
import Slider from "react-slick";
import Layout from "../../components/Layout";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};
var settings = {
  dots: false,
  infinite: true,
  // fade: true,
  arrows: false,
  autoplay: true,
  pauseOnHover: true,
  speed: 2500,
  autoplaySpeed: 2000,
  cssEase: "linear",
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  margin: 30,
  // variableWidth: true,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    "aria-controls": `buyer-dashboard-tabpanel-${index}`,
  };
}

const MyFeeds = (props) => {
  const [value, setValue] = useState(
    props.history.location.state ? props.history.location.state.val : 0
  );

  const handleChange = (event, newValue) => setValue(newValue);

  const [values, setValues] = useState({
    email: "",
  });
  const [Recent, SetRecent] = useState([]);

  const onsubmit = async (e) => {
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      Toast.fire({ type: "error", title: "Email address is invalid!" });
    } else {
      if (!window.location.hostname.includes("localhost")) {
        const res = await request("post", "/mailchimp", {
          type: "mailchimp",
          email: values.email,
        });
        if (res.data.success) {
          setValues({ ...values, email: "" });
          return Popup.fire({ type: "success", title: "Thanks for Subscribe" });
        } else {
          return Toast.fire({ type: "error", title: res.data.msg });
        }
      }
    }
  };

  const handlechange = (e) => {
    setValues({ ...values, email: e.target.value });
  };

  const [content, setContent] = useState([]);

  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "News and Updates",
    });
    if (res && res.data && res.data.status == "true") {
      setContent(res.data.data[0].content);
    }
  };

  useEffect((e) => {
    getContent();
  }, []);

  const [initialvalue, setinitialvalue] = useState({
    closed_auction_list: 0,
  });

  async function recent_list() {
    const sold = await request(
      "post",
      "/mobilesearch_new",
      { closed_auction_list: 1 },
      false,
      true
    );
    SetRecent(sold.data.results);
  }

  useEffect(() => {
    recent_list();
  }, []);
  const parsedContent = ReactHtmlParser(content);
  const contentArray = Array.isArray(parsedContent)
    ? parsedContent
    : [parsedContent];
  const RecentSettingsMob = {
    dots: false,
    row: 2,
    infinite: true,
    slidesToShow: 10,
    arrows: false,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    speed: 2000,
    verticalSwiping: true,
    swipeToSlide: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    touchMove: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 10,
          infinite: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="myFeedsContainer">
      <Layout>
        <div className={"dashboard-wrapper"}>
          <div className="dashboard-content">
            <div className="db-bids-wrapper">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="buyer-dashboard"
              >
                <Tab label="Recent Price Update" {...a11yProps(0)} />
                <Tab label="News & Updates" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="feedTable">
                  <div className="thHead">
                    <div className="text-left">Lot #</div>
                    <div className="text-left">Type</div>
                    <div className="text-right">Weight (Kgs)</div>
                    {/* <div className="text-center">Age (Mths)</div> */}
                    <div className="text-right">
                      Sale Price <br />
                      (€ or €/Kg)
                    </div>
                  </div>
                  <Slider {...settings}>
                    {Recent &&
                      Recent.map((data) => (
                        <div className="tbBody">
                          <div className="text-left">
                            {/* <Link to={"/recent-items-view/" + data.id}>
                              {(data.auctionid + "").replace(/\d(?=\d{4})/g, ".")}
                            </Link> */}
                            {(data.auctionid + "").replace(/\d(?=\d{4})/g, ".")}
                          </div>
                          <div className="text-left">{data.subcategory}</div>
                          <div className="text-right">
                            {!data.avgweight ||
                            data.avgweight == 0 ||
                            data.avgweight == ""
                              ? "N/A"
                              : callNumber(Math.round(data.avgweight))}
                          </div>
                          {/* <div className="text-center">{data.content_head2}</div> */}
                          <div className="text-right">
                            {data?.content_head3 == "Not Sold by Weight"
                              ? callNumber(data.rprice)
                              : !data.avgweight ||
                                data.avgweight == 0 ||
                                data.avgweight == ""
                              ? "N/A"
                              : callNumber(
                                  parseFloat(
                                    data.rprice / data.avgweight
                                  ).toFixed(2)
                                )}
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <div>{ReactHtmlParser(content)}</div> */}
                <div className="newsUContain">
                  {/* <div className="newsUCard"> */}
                  <Slider {...RecentSettingsMob}>
                    {contentArray.map((item, index) => (
                      <div key={index} className="newsUCard">
                        {item}
                      </div>
                    ))}
                  </Slider>
                  {/* <div>{ReactHtmlParser(content)}</div> */}
                  {/* <picture className="newsUPic">
                  <img src="./assets/images/logo-inner.png" alt="logo" />
                </picture>
                <div className="newsUContent">
                  <h4 className="newsUTit">That's Farming</h4>
                  <p className="newsUpara">Farmers can Buy & Sell in minutes</p>
                </div> */}
                  {/* </div> */}
                  {/* <div className="newsUCard">
                <picture className="newsUPic">
                  <img src="./assets/images/logo-inner.png" alt="logo" />
                </picture>
                <div className="newsUContent">
                  <h4 className="newsUTit">That's Farming</h4>
                  <p className="newsUpara">Farmers can Buy & Sell in minutes</p>
                </div>
              </div>
              <div className="newsUCard">
                <picture className="newsUPic">
                  <img src="./assets/images/logo-inner.png" alt="logo" />
                </picture>
                <div className="newsUContent">
                  <h4 className="newsUTit">That's Farming</h4>
                  <p className="newsUpara">Farmers can Buy & Sell in minutes</p>
                </div>
              </div> */}
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default MyFeeds;
